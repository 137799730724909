<!-- 菜单 我的 -->
<template>
  <div class="wrap">
    <div class="mine_info" v-loading="loading">
      <div>
        <!-- <p class="title">我的</p> -->
        <!-- <el-divider></el-divider> -->
        <div class="content-wrap">
          <div class="content">
            <p>
              公司名称：
              <span>{{ data.company_name }}</span>
            </p>
            <p>
              公司行业：
              <span>{{
                company_industry_name === "" ? "" : company_industry_name
              }}</span>

              <!-- <el-divider direction="vertical"></el-divider>
              <span>科技</span>-->
            </p>
            <p>
              公司规模：
              <span
                >{{ data.company_people_min }}-{{
                  data.company_people_max
                }}人</span
              >
            </p>
            <p>
              会员到期时间：
              <span>{{ data.vip_end_time }}</span>
            </p>
            <p v-if="data.integral">
              兑换积分:
              <span>{{ data.integral }}</span>

              <el-button
                v-if="data.isduihuan == 0"
                class="btn left-10"
                @click="goto_duihuan"
                >兑换礼品</el-button
              >
            </p>
          </div>
          <div class="img-wrap">
            <img :src="data.company_logo" alt />
          </div>
        </div>
      </div>
      <!-- <div>
        <p class="title">联系方式</p>
        <el-divider></el-divider>
        <div class="content">
          <p>
            手机：
            <span>111111111</span>
          </p>
        </div>
      </div>-->
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { setMyHome } from "@/api/me";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      data: {},
      company_industry_name: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goto_duihuan() {
      this.$router.replace("/shop_user_goods");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    setMyHome().then((res) => {
      if (res.code === 0) {
        this.loading = false;
        this.data = res.data;

        if (res.data.company_industry_data.second_industry_name != undefined) {
          this.company_industry_name =
            res.data.company_industry_data.first_industry_name +
            res.data.company_industry_data.second_industry_name;
        } else {
          this.company_industry_name = "";
        }
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
  min-width: 1100px;
}
.mine_info {
  max-width: 1200px;
  min-width: 900px;
  // min-height: 500px;
  padding: 20px;
  background-color: #fff;
  /* display: flex;
  align-items: center; */
  box-shadow: $box-shadow;
  border-radius: $radius;
}
/* 分割线 */
/* div /deep/ .el-divider--horizontal {
  width: 90%;
  margin: 0 auto;
} */
// em {
//   display: inline-block;
//   border-left: 1px #e0e0e0 solid;
//   height: 13px;
//   margin: 0 10px;
//   vertical-align: middle;
// }
.text-color {
  color: #ff8f00 !important;
}
/* 标题 */
.title {
  font-size: 16px;
  color: $jd_fontColor;
  margin: 30px 0;
}
// 内容标头
.content p {
  margin: 7px 0 0 30px;
  font-size: 14px;
  color: $selfColor;
}
.content p span {
  font-size: 14px;
  color: $jd_fontColor;
}
.mine_img {
  display: flex;
  align-items: center;
  width: 143px;
  height: 143px;
}
.mine_img img {
  width: 100%;
  height: 100%;
}
.mine_company {
  margin-left: 50px;
}
.mine_company p:nth-child(1) {
  font-size: 30px;
  font-weight: 600;
  color: #333;
}
.mine_company p:nth-child(2) {
  font-size: 18px;
  color: #333;
}
.mine_company p:nth-child(2) span {
  color: #ff8f00;
}
/* 公司信息 */
.company-info {
  display: flex;
  align-items: center;
}
.content-wrap {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}
.img-wrap {
  width: 110px;
  height: 110px;
}
.img-wrap img {
  width: 100%;
}
.left-10 {
  margin-left: 20px;
}
</style>
